import {createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";


import {
    asyncDeleteDriverAction,
    asyncUpdateDriverAction,
    asyncGetDriversAction, asyncGetDriversNumbersAction
} from "./driversAction";
import {IDriver} from "../../../helpers/types";
import {toast} from "react-toastify";
import {tostSetting} from "../../../helpers/scripts";


interface driversState {
    drivers: IDriver[] ;
    totalDrivers:number;
    driver: IDriver | null;
    currentDate:string;
    dates:any;
    numbersList:any[]
    isToday:boolean;
    editMode: boolean;
    currentPage: number;
    selectedCount: number;
    isLoading: boolean;
    error: string
}

const initialState: driversState = {
    drivers: [],
    numbersList:[],
    totalDrivers:0,
    driver: null,
    editMode: false,
    dates: null,
    currentDate: 'yesterday',
    isToday: false,
    currentPage: 1,
    selectedCount: 0,
    isLoading: false,
    error: ''
};


const driversSlice = createSlice({
    name:'driversSlice',
    initialState,
    reducers:{
        changeIsToday(state,action){
          state.isToday = action.payload
        },
        setSelectedDrivers(state,action){
            const selectedList =action.payload ;
            state.drivers = state.drivers.map(item=>{
                    if(selectedList?.includes(item.userID as string)) {
                        return {
                            ...item,
                            isSelected: true
                        }
                    }
                    else{
                        return {
                            ...item,
                            isSelected: false
                        }
                    }
            });
        },
        setSelectedCount(state,action){
            state.selectedCount = action.payload;
        },
        changeEditModeDrivers(state,action){
            state.editMode = action.payload;
        },
        setDates(state,action){
            state.dates = action.payload;
        },
        setCurrentDateDrivers(state,action){
            state.currentDate = action.payload;
        },
        setCurrentPageDrivers(state,action){
            state.currentPage = action.payload;
        },
        resetParticipants(state){
            state = initialState;
        }
    },
    extraReducers: (builder:any) => {
        builder
        .addCase(asyncGetDriversNumbersAction.fulfilled, (state:any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.numbersList = action.payload;
        })
        .addCase(asyncGetDriversNumbersAction.pending, (state:any, action: PayloadAction<any>) => {
            state.isLoading = true;
        })
        .addCase(asyncGetDriversNumbersAction.rejected, (state:any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = action.payload
        })
        .addCase(asyncGetDriversAction.fulfilled, (state:any, action: PayloadAction<any>) => {
            const {rows,count} = action.payload;
            state.isLoading = false;
            state.error = '';
            state.totalDrivers = count;
            const oldItems:string = localStorage.getItem('selectedEmail') ?? '';
            let oldArraySelected:string[] = oldItems ? oldItems?.split(',') : [];
            state.drivers = rows ? rows.map((item:any)=>{
                if(oldArraySelected?.includes(item.email as string)) {
                    return {
                        ...item,
                        isSelected: true
                    }
                }
                else{
                    return {
                        ...item,
                        isSelected: false
                    }
                }
            }) : [];
           // state.participants = rows ? rows: [];
        })
        .addCase(asyncGetDriversAction.pending, (state:any, action: PayloadAction<any>) => {
            state.isLoading = true;
        })
        .addCase(asyncGetDriversAction.rejected, (state:any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = action.payload
        })
        .addCase(asyncUpdateDriverAction.fulfilled, (state:any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.drivers = state.drivers.map((driver:any) =>
                driver.id === action.payload.id ? action.payload : driver
            );
            toast.success("Driver successfully updated",tostSetting);
        })
        .addCase(asyncUpdateDriverAction.pending, (state:any, action: PayloadAction<any>) => {
            state.isLoading = true;
        })
        .addCase(asyncUpdateDriverAction.rejected, (state:any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = action.payload
        })
        .addCase(asyncDeleteDriverAction.fulfilled, (state:any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.drivers = state.drivers.filter((item:IDriver)=>item.id !== action.payload.id);
            toast.success("Driver successfully deleted",tostSetting);
        })
        .addCase(asyncDeleteDriverAction.pending, (state:any, action: PayloadAction<any>) => {
            state.isLoading = true;
        })
        .addCase(asyncDeleteDriverAction.rejected, (state:any, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = action.payload;
            toast.error("Cant delete Driver",tostSetting)
        })
    }
});

export const {setDates,changeIsToday, changeEditModeDrivers,setCurrentDateDrivers,setSelectedDrivers,setSelectedCount,setCurrentPageDrivers,resetParticipants} = driversSlice.actions;
export default driversSlice.reducer;