import './InputGradient.scss';

import React, {FC, ReactNode,KeyboardEvent,ChangeEvent,FocusEvent} from 'react';
import TypedMessage from "../../atoms/TypedMessage/TypedMessage";

interface IInputGradient {
    className?: string;
    classError?: string;
    name: string;
    type?: "text" | "password" | "email";
    placeholder: string;
    ErrorMessage?: ReactNode;
    message?: any;
    onKeyDown?: (e:KeyboardEvent<HTMLInputElement>) => void;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    value?: string | number;
    innerRef?: any;
}
const InputGradient: FC<IInputGradient> = ({innerRef,
                                       name,
                                       className = "",
                                       classError,
                                       type = "text",
                                       placeholder,
                                       onBlur,
                                       onKeyDown=()=>{},
                                       onChange,
                                       value,
                                       message= "",
                                       ErrorMessage}) => {
    return (
        <>
            <div className={`input-gradient ${className}`}>
                <label>
                    <input onKeyDown={onKeyDown} autoComplete={'off'} ref={innerRef} placeholder={' '} type={type} onChange={onChange} onBlur={onBlur} name={name} value={value} />
                    <span className="input-gradient__label">{placeholder}</span>
                    <div className={`input-gradient__message ${message !== "" ? "active" : ""}`}>
                        {message !== "" && <TypedMessage cursor={false} loop={false}  className={'input-gradient__message-text'} strings={[message]} /> }
                    </div>
                </label>
            </div>
            {ErrorMessage && <p style={{color:"#DB3522",fontSize: "12px"}}>{ErrorMessage}</p>}
        </>

    );
};

export default InputGradient;