import instance from "./api";
import {AxiosResponse} from 'axios';
import {AuthResponse, IUser} from "../helpers/types";

export default class AuthService {
    static async login(login: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return instance.post<AuthResponse>('auth/login', {login, password})
    }
    static async registration(login: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return instance.post<AuthResponse>('auth/registration', {login, password})
    }
    static async check(): Promise<AxiosResponse<AuthResponse>> {
        return instance.get<AuthResponse>('auth/check')
    }
    static async logout(): Promise<void> {
        return instance.post('auth/logout')
    }

}