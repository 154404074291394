// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  position: relative;
  list-style: none;
  display: flex;
}
.pagination li {
  margin: 0 5px;
}
.pagination li:first-child svg {
  transform: rotate(-180deg);
}
.pagination li a {
  display: inline-flex;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background: #080707;
  color: var(--accent-color);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}
.pagination li a:hover {
  background: var(--primary-color);
}
@media (max-width: 767px) {
  .pagination li a {
    width: 40px;
    height: 40px;
  }
}
.pagination li.active a {
  background: #285bdf;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/Pagination/Pagination.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,gBAAA;EACA,aAAA;AAAF;AACE;EACE,aAAA;AACJ;AACM;EACE,0BAAA;AACR;AAEI;EACE,oBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,0BCjBI;EDkBJ,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,yBAAA;EACA,qBAAA;AAAN;AACM;EACE,gCCzBD;AD0BP;AACM;EAfF;IAgBI,WAAA;IACA,YAAA;EAEN;AACF;AACK;EACE,mBAAA;AACP","sourcesContent":["@import '../../../styles/_variables';\r\n.pagination{\r\n  position: relative;\r\n  list-style: none;\r\n  display: flex;\r\n  li{\r\n    margin: 0 5px;\r\n    &:first-child{\r\n      svg{\r\n        transform: rotate(-180deg);\r\n      }\r\n    }\r\n    a{\r\n      display: inline-flex;\r\n      width: 50px;\r\n      height: 50px;\r\n      border-radius: 5px;\r\n      background: #080707;\r\n      color:$accent;\r\n      justify-content: center;\r\n      align-items: center;\r\n      cursor: pointer;\r\n      transition: all 0.3s ease;\r\n      text-decoration: none;\r\n      &:hover{\r\n        background: $main;\r\n      }\r\n      @media (max-width:767px){\r\n        width: 40px;\r\n        height: 40px;\r\n      }\r\n    }\r\n    &.active{\r\n     a{\r\n       background: #285bdf;\r\n     }\r\n    }\r\n  }\r\n\r\n\r\n}","$main: var(--primary-color);\r\n$accent:  var(--accent-color);\r\n$additional: var(--additional-color);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
