
export function getTimeDate(dateString:string|number) {
    const date = new Date(dateString);
    return ("0" + (date.getHours())).slice(-2)+':'+("0" + (date.getMinutes())).slice(-2)+':'+("0" + (date.getSeconds())).slice(-2)+' '+("0" + (date.getDate())).slice(-2)+'.'+("0" + (date.getMonth()+1)).slice(-2)+'.'+date.getFullYear();
}

export function getDateRange(optionValue: string) {
    const now = new Date();
    let dateFrom: number, dateTo: number;

    switch (optionValue) {
        case "today":
            dateFrom = new Date(now.setHours(0, 0, 0, 0)).getTime() / 1000;
            dateTo = new Date(now.setHours(23, 59, 59, 999)).getTime() / 1000;
            break;
        case "yesterday":
            dateFrom = new Date(now.setHours(0, 0, 0, 0) - 24 * 60 * 60 * 1000).getTime() / 1000;
            dateTo = new Date(now.setHours(23, 59, 59, 999) - 24 * 60 * 60 * 1000).getTime() / 1000;
            break;
        case "this_week":
            const startOfWeek = new Date(now);
            // Set the day to the Monday of the current week
            startOfWeek.setDate(now.getDate() - now.getDay() + (now.getDay() === 0 ? -6 : 1));
            startOfWeek.setHours(0, 0, 0, 0);
            dateFrom = startOfWeek.getTime() / 1000;

            const endOfWeek = new Date(startOfWeek);
            // Set the day to the Sunday of the current week
            endOfWeek.setDate(startOfWeek.getDate() + 6);
            endOfWeek.setHours(23, 59, 59, 999);
            dateTo = endOfWeek.getTime() / 1000;
            break;

        case "last_week":
            const startOfLastWeek = new Date(now);
            // Calculate the start of the last week
            // If today is Monday (1), startOfLastWeek should be last Monday (-6)
            // If today is Sunday (0), startOfLastWeek should be Monday of the last week (-13)
            startOfLastWeek.setDate(now.getDate() - now.getDay() - (now.getDay() === 0 ? 13 : 6));
            startOfLastWeek.setHours(0, 0, 0, 0);
            dateFrom = startOfLastWeek.getTime() / 1000;

            const endOfLastWeek = new Date(startOfLastWeek);
            // Calculate the end of the last week
            endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);
            endOfLastWeek.setHours(23, 59, 59, 999);
            dateTo = endOfLastWeek.getTime() / 1000;
            break;

        case "this_month":
            dateFrom = new Date(now.getFullYear(), now.getMonth(), 1).setHours(0, 0, 0, 0) / 1000;
            dateTo = new Date(now.getFullYear(), now.getMonth() + 1, 0).setHours(23, 59, 59, 999) / 1000;
            break;
        case "last_month":
            dateFrom = new Date(now.getFullYear(), now.getMonth() - 1, 1).setHours(0, 0, 0, 0) / 1000;
            dateTo = new Date(now.getFullYear(), now.getMonth(), 0).setHours(23, 59, 59, 999) / 1000;
            break;
        case "half_year":
            const startOfHalfYear = now.getMonth() < 6 ? new Date(now.getFullYear(), 0, 1) : new Date(now.getFullYear(), 6, 1);
            dateFrom = startOfHalfYear.setHours(0, 0, 0, 0) / 1000;
            dateTo = now.getMonth() < 6 ? new Date(now.getFullYear(), 5, 30).setHours(23, 59, 59, 999) / 1000 : new Date(now.getFullYear(), 11, 31).setHours(23, 59, 59, 999) / 1000;
            break;
        case "this_year":
            dateFrom = new Date(now.getFullYear(), 0, 1).setHours(0, 0, 0, 0) / 1000;
            dateTo = new Date(now.getFullYear(), 11, 31).setHours(23, 59, 59, 999) / 1000;
            break;
        default:
            throw new Error("Invalid option value");
    }

    return { dateFrom, dateTo };
}

export function getMultiplier(value: number): number {
    let multiplier: number;

    if (value < 3000) {
        multiplier = 0.6;
    } else if (value >= 3000 && value < 3500) {
        multiplier = 0.65;
    } else {
        multiplier = 0.7;
    }

    return multiplier;
}

export function applyMultiplierMoney(value: number): number {

    return value * getMultiplier(value);
}


export function replaceEnterWithBr(text:string) {
    return text.replace(/\n/g, '<br/>');
}

export function replaceAndBoldText(text:string) {
    // Заменяем переносы строки на <br/>
    text = text.replace(/\n/g, '<br/>');
    // Заменяем *текст* на <b>текст</b>
    text = text.replace(/\*(.*?)\*/g, '<b>$1</b>');
    return text;
}


export function replaceBrWithEnter(text:string) {
    return text.replace(/<br\s*\/?>/g, '\n');
}

export function getRandomColor(name: string | undefined): string {
    const initial = name?.charAt(0).toUpperCase();
    const colors: string[] = [
        '#F44336', // red
        '#E91E63', // pink
        '#9C27B0', // purple
        '#673AB7', // deep purple
        '#3F51B5', // indigo
        '#2196F3', // blue
        '#03A9F4', // light blue
        '#00BCD4', // cyan
        '#009688', // teal
        '#4CAF50', // green
        '#8BC34A', // light green
        '#CDDC39', // lime
        '#FFEB3B', // yellow
        '#FFC107', // amber
        '#FF9800', // orange
        '#FF5722', // deep orange
        '#795548', // brown
        '#9E9E9E', // grey
        '#607D8B', // blue grey
    ];
    const index: number = initial ? initial.charCodeAt(0) % colors.length : 0;
    return colors[index];
}

export function getColorBasedOnValue(value: number): string {
    if (value < 9) {
        return 'red';
    } else {
        return 'green';
    }
}
