import {createAsyncThunk} from "@reduxjs/toolkit";
import AuthService from "../../../api/AuthService";
import {IUser} from "../../../helpers/types";


export const asyncRegisterAction = createAsyncThunk(
    'auth/register',
    async (user:any, thunkAPI)=>{
        try {
            const {email, password, phone} = user;
            let response = await AuthService.registration(email, password);
            localStorage.setItem('token',response.data.accessToken)
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);


export const asyncLoginAction = createAsyncThunk(
    'auth/login',
    async (user:any, thunkAPI)=>{
        try {
            const {login,password} = user;
            let response = await AuthService.login(login, password);
            localStorage.setItem('token',response.data.accessToken)
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncCheckAction = createAsyncThunk(
    'auth/check',
    async (_, thunkAPI)=>{
        try {
            let response = await AuthService.check();
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncLogoutAction = createAsyncThunk(
    'auth/logout',
    async (_, thunkAPI)=>{
        try {
            let response = await AuthService.logout();
            //deleteCookie('token');
            localStorage.removeItem('token')
            // localStorage.removeItem('token');
            //thunkAPI.dispatch(resetUsers());
        /*    thunkAPI.dispatch(resetProducts());
            thunkAPI.dispatch(resetCategories());
            thunkAPI.dispatch(resetArticles());*/
            // thunkAPI.dispatch(resetAttributes());
            return response;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);