// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-color);
}
.preloader.relative {
  position: relative;
  border-radius: 8px;
}
.preloader.fixed {
  position: fixed;
  z-index: 200;
}
.preloader.absolute {
  position: absolute;
}

.lds-dual-ring,
.lds-dual-ring:after {
  box-sizing: border-box;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6.4px solid currentColor;
  border-color: currentColor transparent currentColor transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/Preloader/Preloader.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAGA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gCCbK;ADWP;AAGE;EACE,kBAAA;EACA,kBAAA;AADJ;AAGE;EACE,eAAA;EACA,YAAA;AADJ;AAGE;EACE,kBAAA;AADJ;;AAMA;;EAEE,sBAAA;AAHF;;AAKA;EACE,qBAAA;EACA,WAAA;EACA,YAAA;AAFF;;AAIA;EACE,YAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,gCAAA;EACA,+DAAA;EACA,6CAAA;AADF;;AAGA;EACE;IACE,uBAAA;EAAF;EAEA;IACE,yBAAA;EAAF;AACF","sourcesContent":["@import '../../../styles/_variables';\r\n\r\n\r\n.preloader{\r\n  position: fixed;\r\n  top:0;\r\n  left:0;\r\n  width: 100%;\r\n  height: 100%;\r\n  z-index: 100;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  background: $main;\r\n  &.relative{\r\n    position:relative;\r\n    border-radius: 8px;\r\n  }\r\n  &.fixed{\r\n    position:fixed;\r\n    z-index: 200;\r\n  }\r\n  &.absolute{\r\n    position:absolute;\r\n  }\r\n}\r\n\r\n\r\n.lds-dual-ring,\r\n.lds-dual-ring:after {\r\n  box-sizing: border-box;\r\n}\r\n.lds-dual-ring {\r\n  display: inline-block;\r\n  width: 80px;\r\n  height: 80px;\r\n}\r\n.lds-dual-ring:after {\r\n  content: \" \";\r\n  display: block;\r\n  width: 64px;\r\n  height: 64px;\r\n  margin: 8px;\r\n  border-radius: 50%;\r\n  border: 6.4px solid currentColor;\r\n  border-color: currentColor transparent currentColor transparent;\r\n  animation: lds-dual-ring 1.2s linear infinite;\r\n}\r\n@keyframes lds-dual-ring {\r\n  0% {\r\n    transform: rotate(0deg);\r\n  }\r\n  100% {\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n\r\n","$main: var(--primary-color);\r\n$accent:  var(--accent-color);\r\n$additional: var(--additional-color);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
