import './Placeholder.scss';
import {FC} from 'react';

interface IPlaceholder {
    header:string;
    message:string;
}

const Placeholder:FC<IPlaceholder> = ({header,message}) => {
    return (
        <div>
            <div className={'placeholder'}>
                <div className="placeholder__wrap">
                    <div className={'placeholder__icon'}><i className="fa-solid fa-rotate-right"/></div>
                    <div className={'placeholder__header'}>{header}</div>
                    <div className={'placeholder__message'}>{message}</div>
                </div>
            </div>
        </div>
    );
};

export default Placeholder;
