import {createAsyncThunk} from "@reduxjs/toolkit";

import {IDriver} from "../../../helpers/types";
import DriversService from "../../../api/DriversService";




export const asyncGetDriversAction = createAsyncThunk(
    'drivers/all',
    async (query:any, thunkAPI)=>{
        try {
            const {sort,page,limit,search,categoryId,dateFrom, dateTo,today} = query;


            let response = await DriversService.findAll(sort,limit,page,dateFrom,dateTo,today);
            return response.data;


        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);


export const asyncGetBalanceByDateAction = createAsyncThunk(
    'drivers/getBalanceByDate',
    async (query:any, thunkAPI)=>{
        try {
            const {sort,page,limit,search,categoryId,dateFrom, dateTo,today,userID} = query;
            let response = await DriversService.getBalanceByDate(sort,limit,page,dateFrom,dateTo,today,userID);
            return response.data;


        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);


export const asyncGetDriversNumbersAction = createAsyncThunk(
    'drivers/numbers',
    async (_, thunkAPI)=>{
        try {


            let response = await DriversService.findNumbers();
            return response.data;


        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncUpdateDriverAction = createAsyncThunk(
    'drivers/update',
    async (participant:any, thunkAPI)=>{
        try {
            const {id,values} = participant;
            const {vehicleMoney,tracker,licencePlate,percent,expenses} = values;
            let response = await DriversService.update({
                vehicleMoney:Number(vehicleMoney),
                tracker:Number(tracker),
                licencePlate,
                percent: Number(percent),
                expenses: Number(expenses),
                },
                id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncDeleteDriverAction = createAsyncThunk(
    'drivers/delete',
    async (id:number, thunkAPI)=>{
        try {
            let response = await DriversService.delete(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);