// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border-radius: 100px;
  overflow: hidden;
}
.avatar span {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: var(--accent-color);
}
.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/Avatar/Avatar.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,gBAAA;AAAF;AACE;EACE,iCAAA;EACA,gBAAA;EACA,eAAA;EACA,0BCbM;ADcV;AACE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AACJ","sourcesContent":["@import '../../../styles/_variables';\r\n.avatar{\r\n  position:relative;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  width: 38px;\r\n  height: 38px;\r\n  border-radius: 100px;\r\n  overflow: hidden;\r\n  span{\r\n    font-family: \"Roboto\",sans-serif;\r\n    font-weight: 600;\r\n    font-size: 24px;\r\n    color:$accent\r\n  }\r\n  img{\r\n    width: 100%;\r\n    height: 100%;\r\n    object-fit: cover;\r\n  }\r\n}","$main: var(--primary-color);\r\n$accent:  var(--accent-color);\r\n$additional: var(--additional-color);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
