import {createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";
import {
    asyncCheckAction,
    asyncLoginAction,
    asyncLogoutAction,
    asyncRegisterAction,
} from "./authAction";
import {toast} from "react-toastify";

const tostSetting: any = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
}

interface authState {
    sendLogin: boolean;
    user: any;
    isLoading: boolean;
    error: string
}

const initialState: authState = {
    sendLogin: false,
    user: null,
    isLoading: false,
    error: ''
};


const authSlice = createSlice({
    name:'authSlice',
    initialState,
    reducers:{
        changeSendLogin(state,action){
            state.sendLogin = action.payload;
        },
        loadUser(state,action){
            state.user = action.payload;
        },
        logout(state,action){
            state.user = null;
        }
    },
    extraReducers: (builder:any) => {
        builder
            .addCase(asyncLoginAction.fulfilled, (state:any, action: PayloadAction<any>) => {
                const {user} = action.payload;
                state.isLoading = false;
                state.error = '';
                state.user = user;
                state.sendLogin = true;
                toast.success('You\'re login in', tostSetting);
            })
            .addCase(asyncLoginAction.pending, (state:any, action: PayloadAction<string>) => {
            state.isLoading = true;
            })
            .addCase(asyncLoginAction.rejected, (state:any, action: PayloadAction<string>) => {
                state.isLoading = false;
                state.error = action.payload
                toast.error('Can\'t login in', tostSetting);
            })
            .addCase(asyncRegisterAction.fulfilled, (state:any, action: PayloadAction<any>) => {
                const {user} = action.payload;
                state.isLoading = false;
                state.error = '';
                state.user = user;
                toast.success('You successfully logged in', tostSetting);
            })
            .addCase(asyncRegisterAction.pending, (state:any, action: PayloadAction<any>) => {
                state.isLoading = true;
            })
            .addCase(asyncRegisterAction.rejected, (state:any, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action.payload
                toast.error('Can\'t register', tostSetting);
            })
            .addCase(asyncCheckAction.fulfilled, (state:any, action: PayloadAction<any>) => {
                const {user} = action.payload;
                state.isLoading = false;
                state.error = '';
                state.user = action.payload;
            })
            .addCase(asyncCheckAction.pending, (state:any, action: PayloadAction<any>) => {
                state.isLoading = true;
            })
            .addCase(asyncCheckAction.rejected, (state:any, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action.payload// message.error(action.payload);
            })
            .addCase(asyncLogoutAction.fulfilled, (state:any, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = '';
                state.user = null;
                toast.success('You successfully logout', tostSetting);
            })
    }
});

export const {changeSendLogin} = authSlice.actions;
export default authSlice.reducer;