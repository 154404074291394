import {combineReducers,configureStore} from "@reduxjs/toolkit";
import appSlice from "./slices/appSlice";
import authSlice from "./slices/auth/authSlice";
import driversSlice from "./slices/drivers/driversSlice";


const rootReducer = combineReducers({
    app: appSlice,
    auth: authSlice,
    drivers: driversSlice,
});

export const store = configureStore({
    reducer: rootReducer
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch