import './ParticipantItem.scss';
import React, {ChangeEvent, FC, ReactNode, useEffect, useState} from 'react';
import Avatar from "../../atoms/Avatar/Avatar";
import Tag from "../../atoms/Tag/Tag";
import DeleteIcon from "../../atoms/icons/DeleteIcon";
import InputGradient from "../InputGradient/InputGradient";
import EditIcon from "../../atoms/icons/EditIcon";
import {asyncLoginAction} from "../../../redux/slices/auth/authAction";
import {useFormik} from "formik";
import {valSchemaLogin} from "../../../helpers/schemas";
import {getColorBasedOnValue} from "../../../helpers/functions";
import Select from "../../atoms/Select/Select";
import {useSelectorEx} from "../../../hooks/redux";
import {Button} from "react-day-picker";
import RectButton from "../../atoms/RectButton/RectButton";


interface IParticipantItem{
    numbersList?:any[]
    isCheck?:boolean;
    id?:string;
    userName?: string;
    name: string;
    counter?: number;
    money?:ReactNode;
    clue?:boolean
    date?: string;
    type?: ReactNode;
    onSelect?: (checked:boolean)=>void;
    isSelected?:boolean;
    isDelete?:boolean;
    onDelete?: ()=>void
    onSave?: (values:any)=>void;
    onShare?: ()=>void;
    edit?: boolean;
    balanceDriver?:string;
    earnedCar?:string;
    vehicleMoney?:string;
    tracker?:string;
    expenses?:string| number;
    percent?:string | number;
    userID?:string;
}

const ParticipantItem:FC<IParticipantItem> = ({
                                                  onShare=()=>{},
                                                  numbersList=[],
                                                  id,
                                                  userName='',
                                                  isSelected=false,
                                                  isCheck=true,
                                                  onSelect= ()=>{},
                                                  name,
                                                  money,
                                                  date,
                                                  isDelete=false,
                                                  onDelete=()=>{},
                                                  vehicleMoney= '',
                                                  tracker= '',
                                                  onSave=()=>{},
                                                  edit=false,
                                                  balanceDriver=0,
                                                  earnedCar=0,
                                                percent=0,
                                                expenses=0
                                              }) => {
    const [open, setOpen] = useState<boolean>(false)

    const {errors,touched,values,handleChange,handleBlur,handleSubmit,setFieldValue} = useFormik({
        initialValues: {
            vehicleMoney: vehicleMoney ? vehicleMoney : '',
            licencePlate: name ? name : "",
            percent: percent ?? 0,
            expenses: expenses ?? 0,
        },
        enableReinitialize: true,
        validationSchema:null,
        onSubmit: (value) => formHandle(value),
    });
    const formHandle = (values: any)=>{
       onSave(values);
    };
    const changeOpen = ()=>{
        setOpen(!open)
    }

    const trackerDistance:number = (Number(tracker)/1000);


    return (
        <div className={`participant-item ${open ? "active": ""}`} >
            <div  className="participant-item__wrapper">
{/*                {isCheck && <div className="participant-item__select">
                    <Checkbox checked={isSelected} onChange={onSelect}/>
                </div>}*/}
                <div className={"participant-item__inner"} onClick={changeOpen}>
                 {/*   <div className="participant-item__id">ID:{id}</div>*/}
                    <div className="participant-item__avatar"><Avatar name={userName} /></div>
                    <div className="participant-item__user-name">
                        {userName}
                    </div>
                    <div className="participant-item__name"> {edit && !name ?
                        <>
                            <Select
                                //    type={'search'}
                                //defaultValue={'yesterday'}
                                //       className={errors.categoryId && touched.categoryId ? "error": ""}
                                onChange={(value)=>{
                                    setFieldValue('licencePlate',value)
                                }}
                                placeholder={"Номер авто"}
                                items={numbersList.map(item=>{
                                    return {
                                        name: item.licencePlate,
                                        value: item.licencePlate
                                    }
                                })}
                            />
                        </>
                        :
                        name
                    }
                    </div>
                    <div className="participant-item__money">{money} грн.</div>
                    <div className="participant-item__money">{edit ? <Select
                        defaultValue={percent?.toString()}
                        onChange={(value)=>{
                            setFieldValue('percent',value)
                        }}
                        placeholder={"%"}
                        items={[
                            {
                                name: '60%',
                                value: '60'
                            },
                            {
                                name: '65%',
                                value: '65'
                            },
                            {
                                name: '70%',
                                value: '70'
                            },
                        ]}
                    />: `${percent} %`}</div>
                    <div className="participant-item__money">{earnedCar} грн.</div>
                    <div className="participant-item__money">{(trackerDistance).toFixed(2)} km</div>
                    <div className="participant-item__money"> <span style={{color: getColorBasedOnValue((Number(money)/trackerDistance)) }}>{(Number(money)/trackerDistance).toFixed(2)}</span></div>
                  {/*  {edit ? <div className="participant-item__inputs">
                        <form onSubmit={handleSubmit}>
                        <InputGradient name={'vehicleMoney'} placeholder={'Витрати на авто'} onChange={handleChange} onBlur={handleBlur} value={values.vehicleMoney} ErrorMessage={errors.vehicleMoney && touched.vehicleMoney && errors.vehicleMoney} />
                        <a onClick={()=>handleSubmit()}><EditIcon/></a>
                        </form>
                    </div> :*/}

                    <div className="participant-item__money">{balanceDriver}</div>
                    <div className="participant-item__money">{edit ? <InputGradient name={'expenses'} placeholder={'Расходы'} onChange={handleChange} onBlur={handleBlur} value={values.expenses} ErrorMessage={errors.expenses && touched.expenses && errors.expenses} /> : `${expenses} грн.` }</div>
                    {!edit ?
                        <div className="participant-item__money"> <RectButton onClick={onShare} name={'Поделиться'} /> </div>
                        : ""}

                    {edit ?
                        <div className="participant-item__money"><RectButton onClick={()=>handleSubmit()} name={'Обновить'} /></div>
                        : ""}
                  {/*  <div className="participant-item__date"><Tag name={`${date}`} color={"#104100"}/></div>*/}

                </div>

            </div>
{/*            <div className="participant-item__content">
                <div className="participant-item__content-wrapper">
                    <div className="participant-item__content-header">Детали</div>
                    <div className="participant-item__content-inner">
                        <div className="participant-item__content-block">
                            <div className="participant-item__money">Общий доход: {money}  грн.</div>
                            <div className="participant-item__money">Доход автомобиля: {earnedCar}</div>
                            <div className="participant-item__money">Трекер: {(trackerDistance).toFixed(2)} км</div>
                            <div className="participant-item__money">Баланс водителя: {balanceDriver}</div>
                            <div className="participant-item__money">Коэффициент: <span style={{color: getColorBasedOnValue((Number(money)/trackerDistance)) }}>{(Number(money)/trackerDistance).toFixed(2)}</span></div>
                            <div className="participant-item__money">Расходы на авто: {vehicleMoney} грн.</div>
                            <div className="participant-item__money">Расходы: {expenses} грн.</div>
                            <div className="participant-item__money">Процент: {percent} %.</div>
                        </div>
                        <div className="participant-item__content-block">
                            {edit ? <div className="participant-item__content-inputs">
                                    <div className="participant-item__input">
                                    <Select
                                        defaultValue={percent?.toString()}
                                        //    type={'search'}
                                        //defaultValue={'yesterday'}
                                        //       className={errors.categoryId && touched.categoryId ? "error": ""}
                                        onChange={(value)=>{
                                            setFieldValue('percent',value)
                                        }}
                                        placeholder={"Процент %"}
                                        items={[
                                            {
                                                name: '60%',
                                                value: '60'
                                            },
                                            {
                                                name: '65%',
                                                value: '65'
                                            },
                                            {
                                                name: '70%',
                                                value: '70'
                                            },
                                        ]}
                                    />
                                </div>
                                    <div className="participant-item__input">
                                        <InputGradient name={'expenses'} placeholder={'Расходы'} onChange={handleChange} onBlur={handleBlur} value={values.expenses} ErrorMessage={errors.expenses && touched.expenses && errors.expenses} />

                                    </div>

                                    <div className="participant-item__input">
                                        <InputGradient name={'vehicleMoney'} placeholder={'Расходы на автомобиль'} onChange={handleChange} onBlur={handleBlur} value={values.vehicleMoney} ErrorMessage={errors.vehicleMoney && touched.vehicleMoney && errors.vehicleMoney} />
                                    </div>
                                    <div className="participant-item__input">
                                          <RectButton onClick={()=>handleSubmit()} name={'Обновить'} />
                                          <RectButton onClick={onShare} name={'Поделиться'} />
                                    </div>
                            </div> : "" }
                        </div>


                    </div>
                </div>
            </div>*/}

        </div>
    );
};

export default ParticipantItem;