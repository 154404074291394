import React, {FC} from 'react';
import {Outlet} from "react-router-dom";
import AdminLayout from "../components/templates/AdminLayout";

const AdminRoutes:FC = () => {
    return (
        <AdminLayout>
            <Outlet/>
        </AdminLayout>
    );
};

export default AdminRoutes;