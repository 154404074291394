import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {asyncSendCodeAction, asyncSendSuccessCodeAction} from "./appAction";

interface appState {
    modalSuccess:boolean;
    modalErrorMessage:boolean;
    showClue: boolean;
    showSneer: boolean;
    showGiveUp: number;
    isLoading: boolean;
    preloader:boolean;
    error: string;
    errorCode: string;
    successCode: string;
    successMessage: string;
    successMessageError: string;
}

const initialState: appState = {
    modalSuccess: false,
    showSneer: false,
    showGiveUp: 0,
    showClue: false,
    modalErrorMessage:false,
    preloader:true,
    isLoading: false,
    error: '',
    errorCode: '',
    successCode: '',
    successMessage: '',
    successMessageError: ""
};


const appSlice = createSlice({
    name:'appSlice',
    initialState,
    reducers:{
        changeClue(state,action){
            state.showClue = action.payload;
        },
        changeSneer(state,action){
            state.showSneer = action.payload;
        },
        changeGiveUp(state,action){
            state.showGiveUp = action.payload;
        },
        changeModalSuccess(state,action){
            state.modalSuccess = action.payload;
        },
        changeModalErrorMessage(state,action){
            state.modalErrorMessage = action.payload;
        },
        changePreloader(state,action){
            state.preloader = action.payload;
        }
    },
    extraReducers: (builder:any) => {
        builder
            .addCase(asyncSendCodeAction.fulfilled, (state:any, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.errorCode = '';
                state.modalSuccess = true;
                state.successCode = action.payload.code;
                // Handle other state updates based on action.payload if needed
            })
            .addCase(asyncSendCodeAction.pending, (state:any, action: PayloadAction<string>) => {
                state.isLoading = true;
                state.errorCode = "";
                // Handle other state updates based on action.payload if needed
            })
            .addCase(asyncSendCodeAction.rejected, (state:any, action: PayloadAction<string>) => {
                state.isLoading = false;
                state.errorCode = "Wrong code";
                // Handle other state updates based on action.payload if needed
            })
            .addCase(asyncSendSuccessCodeAction.fulfilled, (state:any, action: PayloadAction<any>) => {
                    state.isLoading = false;
                    state.errorCode = '';
                    state.modalSuccess = true;
                    state.successMessage ="I'll be in touch soon";
                    state.successMessageError = ""
                    // Handle other state updates based on action.payload if needed
                })
            .addCase(asyncSendSuccessCodeAction.rejected, (state:any, action: PayloadAction<any>) => {
                const {message} = action.payload
                state.isLoading = false;
                state.successMessage="";
                state.successMessageError=message;
                // Handle other state updates based on action.payload if needed
            });
    },
});
export const {changeGiveUp,changeClue,changeSneer} = appSlice.actions;
export default appSlice.reducer;