import instance from "./api";
import {AxiosResponse} from 'axios';
import {IDriver} from "../helpers/types";


export default class DriversService {
    static async findAll(sort?:string,limit?:number,page?:number,dateFrom?:number,dateTo?:number,today=false): Promise<AxiosResponse<any>> {
        return instance.get<IDriver[]>('drivers/',{
            params: {
                sort,
                limit,
                page,
                dateFrom,
                dateTo,
                today
            }
        } )
    }

    static async getBalanceByDate(sort?:string,limit?:number,page?:number,dateFrom?:number,dateTo?:number,today=false,userID?:string): Promise<AxiosResponse<any>> {
        return instance.get<IDriver[]>('drivers/getBalance',{
            params: {
                sort,
                limit,
                page,
                dateFrom,
                dateTo,
                today,
                userID
            }
        } )
    }

    static async findNumbers(): Promise<AxiosResponse<any>> {
        return instance.get<IDriver[]>('drivers/getNumbers',{
            params: {

            }
        } )
    }

    static async create(participant:FormData): Promise<AxiosResponse<IDriver>> {
        return instance.post<IDriver>(`drivers`,participant)
    }

    static async update({vehicleMoney,tracker,licencePlate,percent,expenses}:any,participantID:number): Promise<AxiosResponse<IDriver>> {
        return instance.put<IDriver>(`drivers/${participantID}`, {
            vehicleMoney,
            tracker,
            licencePlate,
            percent,
            expenses
        })
    }

    static async delete(participantID:number): Promise<AxiosResponse<IDriver>> {
        return instance.delete<IDriver>(`drivers/${participantID}`)
    }

}