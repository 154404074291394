import instance from "./api";
import {AxiosResponse} from 'axios';


export default class MailService {
    static async sendSuccessCode(email:string,successCode:string): Promise<AxiosResponse<any>> {
        return instance.post<any>('participants/form/successCode', {
            email,
            successCode
        })
    }
    static async sendCode(code:string): Promise<AxiosResponse<any>> {
        return instance.post<any>('participants/form/code', {
            code,
        })
    }
    static async getClue(email:string): Promise<AxiosResponse<any>> {
        return instance.post<any>('participants/form/clue', {
            email
        })
    }

}