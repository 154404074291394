// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.placeholder {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.placeholder__wrap {
  max-width: 100%;
  width: 100%;
  background: var(--primary-color);
  padding: 30px 20px;
  border-radius: 20px;
  color: #f4f4f4;
  transition: all 0.3s ease;
  cursor: pointer;
}
.placeholder__wrap:hover {
  background: #f4f4f4;
  color: var(--primary-color);
}
.placeholder__icon {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
}
.placeholder__icon i {
  font-size: 40px;
}
.placeholder__header {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 0;
}
.placeholder__message {
  display: flex;
  justify-content: center;
  width: 100%;
}
@media (max-width: 992px) {
  .placeholder {
    max-width: 760px;
    margin: 0 auto 60px;
  }
}
@media (max-width: 767px) {
  .placeholder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/Placeholder/Placeholder.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,aAAA;EACA,eAAA;EACA,uBAAA;AADF;AAEE;EACE,eAAA;EACA,WAAA;EACA,gCCVG;EDWH,kBAAA;EACA,mBAAA;EACA,cAAA;EACA,yBAAA;EACA,eAAA;AAAJ;AACI;EACE,mBAAA;EACA,2BClBC;ADmBP;AAEE;EACE,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,WAAA;AAAJ;AACI;EACE,eAAA;AACN;AAEE;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;AAAJ;AAEE;EACE,aAAA;EACA,uBAAA;EACA,WAAA;AAAJ;AAKE;EA5CF;IA6CI,gBAAA;IACA,mBAAA;EAFF;AACF;AAGE;EAhDF;IAiDI,aAAA;IACA,eAAA;IACA,uBAAA;EAAF;AACF","sourcesContent":["@import '../../../styles/_variables';\r\n\r\n.placeholder{\r\n  position: relative;\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  justify-content: center;\r\n  &__wrap{\r\n    max-width: 100%;\r\n    width: 100%;\r\n    background:$main;\r\n    padding: 30px 20px;\r\n    border-radius: 20px;\r\n    color:#f4f4f4;\r\n    transition: all 0.3s ease;\r\n    cursor: pointer;\r\n    &:hover{\r\n      background: #f4f4f4;\r\n      color:$main;\r\n    }\r\n  }\r\n  &__icon{\r\n    position: relative;\r\n    display: flex;\r\n    justify-content: center;\r\n    width: 100%;\r\n    i{\r\n      font-size: 40px;\r\n    }\r\n  }\r\n  &__header{\r\n    width: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n    font-size: 20px;\r\n    font-weight: bold;\r\n    padding: 10px 0;\r\n  }\r\n  &__message{\r\n    display: flex;\r\n    justify-content: center;\r\n    width: 100%;\r\n\r\n  }\r\n\r\n\r\n  @media (max-width:992px){\r\n    max-width: 760px;\r\n    margin: 0 auto 60px;\r\n  }\r\n  @media (max-width:767px){\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: center;\r\n\r\n  }\r\n}","$main: var(--primary-color);\r\n$accent:  var(--accent-color);\r\n$additional: var(--additional-color);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
