import './TypedMessage.scss';
import React, {FC, useEffect, useRef} from 'react';
import Typed from "typed.js";

interface ITypedMessage{
    className?: string;
    strings?: string[];
    startDelay?: number;
    onComplete?: (self:any)=>void;
    onCompleteTyped?: (self:any)=>void;
    loop?:boolean;
    cursor?: boolean;
    typeSpeed?: number;
    startCursor?: boolean
}
const TypedMessage:FC<ITypedMessage> = ({strings,
                                            className='',
                                            onComplete=()=>{},
                                            onCompleteTyped=()=>{},
                                            loop=true,
                                            cursor=true,
                                            typeSpeed = 35,
                                            startCursor=true,
                                            startDelay = 0
}) =>{
    const typedRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!typedRef.current) return;

        const options: any = {
            strings: strings,
            typeSpeed: typeSpeed,
            backSpeed: 30,
            backDelay: 3500,
            startDelay: startDelay,
            loop: loop, // Чтобы текст повторялся
            cursorChar: '_',
            onLastStringBackspaced: (self:any) => onComplete(self),
            onBegin: (self:any) => {
                if(!startCursor){
                    self.cursorChar='';
                }
            },
            onComplete: function(self:any) {
                onCompleteTyped(self);
                if(!cursor){
                    self.cursor.remove();
                }
            },

        };

        const typed = new Typed(typedRef.current, options);

        return () => {
            typed.destroy(); // Clean up Typed instance on unmount
        };
    }, [strings]);


    return (
        <span ref={typedRef} className={`typed-message ${className}`}>

        </span>
    );
};

export default React.memo(
    TypedMessage,
    (prevProps, nextProps) => prevProps.strings !== nextProps.strings
);