import React, {FC, useEffect} from 'react';
import {useDispatchEx} from "../../hooks/redux";
import {ToastContainer} from "react-toastify";
import Sidebar from "../organisms/Sidebar/Sidebar";

interface ILayout {
    children: React.ReactNode;
}
const AdminLayout:FC<ILayout> = ({children}) => {
    const dispatch = useDispatchEx();
    useEffect(()=>{
        window.scrollTo({top:0, behavior: 'smooth' });
    },[]);
    return (
        <>
            <ToastContainer />
            <main className={'main-admin'}>
                <Sidebar/>
                {children}
            </main>
        </>
    );
};

export default AdminLayout;