import {createAsyncThunk} from "@reduxjs/toolkit";

import MailService from "../../api/MailService";

export const asyncSendCodeAction = createAsyncThunk(
    'form/codeSend',
    async (message:string, thunkAPI)=>{
        try {
            let response = await MailService.sendCode(message);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);


export const asyncGetClueAction = createAsyncThunk(
    'form/clueSend',
    async (email:string, thunkAPI)=>{
        try {
            let response = await MailService.getClue(email);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncSendSuccessCodeAction = createAsyncThunk(
    'form/sendSuccessCode',
    async (payload:any, thunkAPI)=>{
        try {
            const {email, successCode} =payload;
            let response = await MailService.sendSuccessCode(email,successCode);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data)
        }
    }
);

