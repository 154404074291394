import { useSelectorEx } from './redux';

function useUserRole() {
    const { user } = useSelectorEx(state => state.auth);

    // Return an array of role titles from the roles array
    const roles = user?.roles?.map((role:any) => role.title) || [];

    // Check if the user has a certain role
    function hasUserRole(roleTitle:string) {
        return roles?.includes(roleTitle);
    }

    return [roles, hasUserRole];
}

export default useUserRole;