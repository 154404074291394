import {FC, useEffect} from "react";
import {Navigate, Outlet, useLocation } from "react-router-dom";

import useUserRole from "../hooks/useUserRole";
import { useSelectorEx} from "../hooks/redux";
import Preloader from "../components/molecules/Preloader/Preloader";

interface IRequireAuth{
    role:string
}
const RequireAuth:FC<IRequireAuth> = ({role}) => {
    const token = localStorage.getItem('token');
    const location = useLocation();

    const [roles, hasUserRole] = useUserRole();
    const {user, isLoading} = useSelectorEx(state => state.auth)

    if(!user && token) return <Preloader/>
    return (
        hasUserRole(role)
            ? <Outlet/>
            : <Navigate to={'/login'} state={{from: location}} replace />
    );
};

export default RequireAuth;