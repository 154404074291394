import './Avatar.scss';
import React, {FC} from 'react';
import {getRandomColor} from "../../../helpers/functions";

interface IAvatar{
    name?: string;
    image?: string;
    type?: boolean;
}

const Avatar:FC<IAvatar> = ({ name='',image ='',type=true }) => {
    const initial = name?.charAt(0).toUpperCase();
    return (
        <div className="avatar" style={{ backgroundColor: getRandomColor(name) }}>
            {name ?
                <span className="avatar__letter">{initial}</span>
                :
                <img src={image} alt=""/>
            }
        </div>
    );
};

export default Avatar;